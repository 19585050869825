const stringValidator = {

    isNullOrEmpty : function (str) {
        return (str === null || str === undefined || str.toString().trim() === "");
    },

    isNotNullOrEmpty : function (str) {
        return !this.isNullOrEmpty(str);
    },

    isValidName : function (str) {
        return this.isNotNullOrEmpty(str) && /^[A-Za-záéíóúñÁÉÍÓÚÑ\s'-]+$/.test(str);
    },

    isValidMail : function (str) {
        return this.isNotNullOrEmpty(str) && /^[\w\\.-]+@[\w\\.-]+\.\w+$/.test(str);
    },

    isValidInteger : function (str) {
        return this.isNotNullOrEmpty(str) && /^[0-9]+$/.test(str);
    },

    isValidDecimal : function (str) {
        return this.isNotNullOrEmpty(str) && /^[0-9]+(\.[0-9]+)?$/.test(str);
    }
}

export default stringValidator;