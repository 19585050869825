import React, {Component} from "react";
import arrowIcon from "../../../assets/icon/flecha-hacia-abajo.png";

class AperturaBolsa extends Component {
    render() {
        return (
            <div style={this.props.style}>
                <div>Apertura de Bolsa</div>
                <div>
                    <img style={{width:"15px"}} src={arrowIcon} title={"apertura de bolsa"} alt={"apertura de bolsa"}/>
                </div>
            </div>
        );
    }
}

export default AperturaBolsa;