import React, { Component } from "react";
import TitleCenter from "../common/title-center";
import L from "../i18n/label.json";
import CotizarForm from "./cotizar-form";

class CotizarContent extends Component {
    render() {
        return (
            <div className={"ptb-5"}>
                <TitleCenter customTitle={L.TITULO.COTIZA} />
                <div className={"align-content-center width-50 marl-25"}>
                    <p>
                        ¡Solicita una cotización personalizada!<br/><br/>Estamos listos para brindarte un
                        presupuesto a medida para tus necesidades. Grupo Ades está aquí para tí.<br/><br/>
                        Queremos formar parte del éxito de tu negocio al ofrecerte nuestros productos y servicios.
                        Contáctanos ahora mismo y permítenos ser tu aliado estratégico.
                    </p>
                </div>
                <CotizarForm />
            </div>
        );
    }
}

export default CotizarContent;