import React, { Component } from "react";
import Navigation from "../common/navigation";
import HeroImage from "./hero-image";
import OurProducts from "./our-products";
import Capacity from "./capacity";
import VariableFactor from "./variable-factor";
import Footer from "../common/footer";

class ProductsPage extends Component {
    render() {
        return (
            <div className="App">
                <Navigation lightFont={true} />
                <HeroImage/>
                <OurProducts/>
                <Capacity/>
                <VariableFactor/>
                <Footer/>
            </div>
        );
    }
}

export default ProductsPage;