import React, { Component } from "react";

class Card extends Component {
    render() {
        return(
            <div className="col-sm-3 text-center border-card shadow-card">
                <h2 className={"title-card"}>{this.props.titleText}</h2>
                <p className={"p-20"}>{this.props.bodyText}</p>
            </div>
        );
    }
}

export default Card;