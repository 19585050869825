import React, {Component} from "react";
import fuelleImgRV from "../../../assets/preview/fuelle-rectangulo-vertical.png";
import SecondImageVertical from "../second-preview/second-image-vertical";
import BolsaSueltaRolloSelloFondoTitle from "../title-preview/bolsa-suelta-rollo-sello-fondo-title";
import AperturaBolsa from "../title-preview/apertura-bolsa";

class IsoVertical extends Component {
    render() {
        const jsonObj = this.props.jsonObj;

        return (
            <div className={"iso-vertical"}>
                <BolsaSueltaRolloSelloFondoTitle jsonObj={jsonObj} />
                <div className={"preview-image pt-5"}>
                    <div> {/* FIRST IMAGE */}
                        <AperturaBolsa style={{paddingLeft:"75px"}}/>
                        <div>
                            <span style={{marginLeft:"16%"}}>{jsonObj.dimensiones.fuelle} {jsonObj.dimensiones.unidad}</span>
                            <span style={{paddingLeft:"19%"}}>{jsonObj.dimensiones.fuelle} {jsonObj.dimensiones.unidad}</span>
                        </div>
                        <div>
                            <span>{jsonObj.dimensiones.largo} {jsonObj.dimensiones.unidad}</span>
                            <img src={ fuelleImgRV } alt={"bolsa con fuelle"}/>
                        </div>
                        <div style={{paddingLeft:"18%", marginTop:"-10px"}}>{jsonObj.dimensiones.ancho} {jsonObj.dimensiones.unidad}</div>
                    </div>
                    <SecondImageVertical jsonObj={jsonObj}/>
                </div>
            </div>
        );
    }
}

export default IsoVertical;