import React, { Component } from "react";
import L from "../../i18n/label.json";
import TextField from "@mui/material/TextField";
import {FormControl} from "@mui/material";

class General extends Component {

    onInputChange = (evt) => {
        this.props.updateState({
            [evt.target.name] : evt.target.value
        });
    }

    render() {
        return (
            <div>
                <div className="form-group align-start">
                    <h4>{L.FORM.SECCION.GRAL}</h4>
                </div>
                <div className="form-group">
                    <FormControl fullWidth={true}>
                        <TextField name="form_gral_nombre" defaultValue="" onChange={this.onInputChange} label={L.FORM.NOMBRE} variant="standard" />
                    </FormControl>
                </div>
                <div className="form-group">
                    <FormControl fullWidth={true}>
                        <TextField name="form_gral_empresa" defaultValue="" onChange={this.onInputChange} label={L.FORM.EMPRESA} variant="standard" />
                    </FormControl>
                </div>
                <div className="form-group">
                    <FormControl fullWidth={true}>
                        <TextField name="form_gral_correo" defaultValue="" onChange={this.onInputChange} label={L.FORM.CORREO} variant="standard" />
                    </FormControl>
                </div>
                <div className="form-group">
                    <FormControl fullWidth={true}>
                        <TextField name="form_gral_tel" defaultValue="" onChange={this.onInputChange} label={L.FORM.TEL} variant="standard" />
                    </FormControl>
                </div>
            </div>
        );
    }
}

export default General;