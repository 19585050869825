import React from "react";
import Navigation from "./components/common/navigation";
import Footer from "./components/common/footer";

import AboutSection from "./components/home/about"
import ProductsSection from "./components/home/products";
import Contact from "./components/home/contact";

import CoverOne from "./components/home/cover-photo/cover-one";
import CoverTwo from "./components/home/cover-photo/cover-two"
import CoverThree from "./components/home/cover-photo/cover-three";

import "./styles/App.css";
import "./styles/index.css";

function App() {
    return (
        <div className="App">
            <Navigation lightFont={true} />
            <CoverOne/>
            <AboutSection/>
            <CoverTwo/>
            <ProductsSection/>
            <CoverThree/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default App;
