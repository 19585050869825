import React, { Component } from "react";
import TitleCenter from "../common/title-center";
import L from "../i18n/label.json";

class Capacity extends Component {
    render() {
        return (
            <>
                <TitleCenter customTitle={L.TITULO.CAPACIDAD}/>
                <div className="container-fluid wow fadeIn" id="missionSectionId" data-wow-duration="1.5s">
                    <div className="row p-20">
                        <div className="col-lg-7 has-img-extrucion"></div>
                        <div className="col-lg-5">
                            <div className="row">
                                <div className="col-sm-12 pt-4 product">
                                    <h3 className={"title-left pl-0"}>{L.TITULO.EXTRUC}</h3>
                                    <ul className={"pl-4"}>
                                        <li>7 Extrusores</li>
                                        <li>Medidas Ancho: 15 cm - 300 cm (con fuelle)</li>
                                        <li>Capacidad instalada 800 Tons.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row p-20">
                        <div className="col-lg-7 has-img-bolseo"></div>
                        <div className="col-lg-5">
                            <div className="row">
                                <div className="col-sm-12 pt-4 product">
                                    <h3 className={"title-left pl-0"}>{L.TITULO.BOLSEO}</h3>
                                    <ul className={"pl-4"}>
                                        <li>10 Bolseadoras</li>
                                        <li>Medidas Ancho: 15 cm - 190 cm (incluyendo fuelle) largo 15 cm - 290 cm</li>
                                        <li>4 bolseadoras sello de fondo</li>
                                        <li>4 bolseadoras sello lateral</li>
                                        <li>2 bolseadoras en rollo</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Capacity;