import React, {Component} from "react";
import isoImgRV from "../../../assets/preview/isom-cubo.png";
import AperturaBolsa from "../title-preview/apertura-bolsa";

class SecondImageSelloLateralCuadro extends Component {
    render() {
        const jsonObj = this.props.jsonObj;

        return (
            <div className={"pt-5"}>
                <AperturaBolsa style={{paddingLeft:"0px"}}/>
                <div>
                    <span>{jsonObj.dimensiones.largo} {jsonObj.dimensiones.unidad}</span>
                    <img src={ isoImgRV } alt={"isometrico"}/>
                </div>
                <div>
                    <span style={{marginLeft:"-8%"}}>{jsonObj.dimensiones.profundidad} {jsonObj.dimensiones.unidad}</span>
                    <span style={{paddingLeft:"20%"}}>{(jsonObj.dimensiones.ancho-jsonObj.dimensiones.profundidad).toFixed(2)} {jsonObj.dimensiones.unidad}</span>
                </div>
            </div>
        );
    }
}

export default SecondImageSelloLateralCuadro;