import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import App from './App';
import NosotrosPage from "./components/nosotros/nosotros-page";
import ProductsPage from "./components/products/products-page";
import CotizarPage from "./components/cotizador/cotizar-page";
import NotFound404 from "./components/common/not-found-404";
import reportWebVitals from './reportWebVitals';
import ScrollToTop from "./components/common/scroll-to-top";

import './styles/index.css';
import L from "./components/i18n/label.json";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <ScrollToTop />
      <Routes>
          <Route path={L.MENU.INICIO.URL} element={<App />} />
          <Route path={L.MENU.NOSOTROS.URL} element={<NosotrosPage />} />
          <Route path={L.MENU.PRODUCTOS.URL} element={<ProductsPage />} />
          <Route path={L.MENU.COTIZA.URL} element={<CotizarPage />} />
          <Route path={"*"} element={<NotFound404/>} />
      </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
