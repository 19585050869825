import React, {Component} from "react";
import planoImgRV from "../../../assets/preview/plano-rectangulo-vertical.png";
import BolsaLaminaTitle from "../title-preview/bolsa-lamina-title";

class PlanoVertical extends Component {
    render() {
        const jsonObj = this.props.jsonObj;

        return (
            <div className={"pl-image-vertical"}>
                <BolsaLaminaTitle jsonObj={jsonObj} />
                <div className={"preview-image pt-5"}>
                    <div>
                        <span>{jsonObj.dimensiones.largo} {jsonObj.dimensiones.unidad}</span>
                        <img src={ planoImgRV } alt={"preview"}/>
                    </div>
                    <div style={{paddingLeft:"15%", marginTop:"-15px"}}>{jsonObj.dimensiones.ancho} {jsonObj.dimensiones.unidad}</div>
                </div>
            </div>
        );
    }
}

export default PlanoVertical;
