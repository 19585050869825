import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Component} from "react";
import sv from "../validator/string-validator";

class WarningDialog extends Component {


    constructor(props) {
        super(props);

        this.state = {
            open : false,
            titleText : "Atención",
            content : "",
            bgColor : ""
        }
    }
    componentDidMount() {
        this.props.setDialog(this);
    }

    openDialog = (isVisible, type, title, contentMsg, trigger) => {
        if(!sv.isNullOrEmpty(contentMsg)) {
            let bgColor = "";
            switch (type) {
                case "I": // Info
                    bgColor = "#67adef";
                    break;
                case "W": // Warning
                    bgColor = "#f8f26d";
                    break;
                case "E": // Error
                    bgColor = "#fa6a65";
                    break;
                case "S": // Success
                    bgColor = "#76ea57";
                    break;
                default: // unknown
                    bgColor = "";
                    return;
            }
            this.setState({
                open : isVisible,
                titleText : title,
                content: contentMsg,
                bgColor : bgColor,
                trigger: trigger
            });
        } else {
            this.setState({open : isVisible});
        }
    }

    closeDialog = () => {
        this.openDialog(false);
        if(sv.isNotNullOrEmpty(this.state.trigger)) {
            this.state.trigger();
        }
    }

    render() {
        return (
            <Dialog
                open={this.state.open}
                onClose={() => this.closeDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ backgroundColor:this.state.bgColor, borderBottom: "solid 1px", marginBottom: "45px" }}>
                    {this.state.titleText}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.state.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.closeDialog()}>ENTENDIDO</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
export default WarningDialog;