import React, { Component } from "react";

class HeroImage extends Component {
    render() {
        return (
            <header id="heroCoverCotizadorImage" className="header header-bg-cover-5">
                <div className="overlay"></div>
            </header>
        );
    }
}

export default HeroImage;