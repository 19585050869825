import React, { Component } from "react";

class TitleCenterWide extends Component {
    render() {
        return(
            <div className="row title-center">
                <div className="col-sm-4">
                    <hr className="hr-line-left hr-line-lw"/>
                </div>
                <div className="col-sm-4">
                    <h2>{this.props.customTitle}</h2>
                </div>
                <div className="col-sm-4">
                    <hr className="hr-line-right hr-line-rw"/>
                </div>
            </div>
        );
    }
}

export default TitleCenterWide;