import React, { Component } from "react";

class TitleCenter extends Component {
    render() {
        return(
            <div className="row title-center">
                <div className="col-sm-45">
                    <hr className="hr-line-left"/>
                </div>
                <div className="col-sm-3">
                    <h2>{this.props.customTitle}</h2>
                </div>
                <div className="col-sm-45">
                    <hr className="hr-line-right"/>
                </div>
            </div>
        );
    }
}

export default TitleCenter;