import React, { Component } from "react";
import Navigation from "../common/navigation";
import Footer from "../common/footer";
import HeroImage from "./hero-image";
import CotizarContent from "./cotizar-content";

class CotizarPage extends Component {
    render() {
        return(
            <div className="App">
                <Navigation lightFont={true} />
                <HeroImage />
                <CotizarContent />
                <Footer/>
            </div>
        );
    }
}

export default CotizarPage;