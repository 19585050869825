import React, { Component } from "react";
import L from "../i18n/label.json";

import General from "./form/general";
import Especificaciones from "./form/especificaciones";
import Presentacion from "./form/presentacion";
import Material from "./form/material";
import Aditivos from "./form/aditivos";
import Impresion from "./form/impresion";
import ImagePreview from "./image-preview";
import {Container} from "@mui/material";

import iv from "../validator/image-validator";
import fv from "../validator/form-validator";
import mh from "../helper/mail-helper";
import WarningDialog from "../dialog/warning-dialog";
import ReactDOM from "react-dom/client";

class CotizarForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form_gral_nombre : "",
            form_gral_empresa : "",
            form_gral_correo : "",
            form_gral_tel : "",
            form_specs_ancho : "",
            form_specs_ancho_unit : "",
            form_specs_largo : "",
            form_specs_largo_unit : "",
            form_specs_fuelle : "",
            form_specs_fuelle_unit : "",
            form_specs_calibre : "",
            form_specs_calibre_unit : "",
            form_present_bl : "",
            form_present_sr : "",
            form_present_otro : "",
            form_present_sello : "",
            form_material_ab : "",
            form_material_pt : "",
            form_material_color : "",
            form_aditivo_na : "",
            form_aditivo_otro : "",
            form_impresion_sn : "",
            form_impresion_num : "",
            form_image_preview : "",
            cleanUpFuelleFields : ""
        }

        this.imagePreviewDiv = React.createRef();
        this.cotizarFormRef = React.createRef();
        this.dialog = null;
        this.rootDomNode = null;
    }

    setDialog = (_dialog) => {
        this.dialog = _dialog;
    }

    /**
     * initializing rootDomNode
     */
    componentDidMount() {
        this.rootDomNode = ReactDOM.createRoot(this.imagePreviewDiv.current);
    }

    /**
     * updating information after a change
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        const _previewData = iv.validatePreview(this.state);
        if(_previewData) iv.updateImagePreview(this.rootDomNode, this.imagePreviewDiv, _previewData);
    }

    /**
     * main method to update the state
     * @param jsonData
     */
    updateState = (jsonData) => {
        this.setState(jsonData);
    }

    cleanUpFuelleFields = () => {
        console.info("state", this.state);
        this.state.cleanUpFuelleFields();
    }

    /**
     * handler for submitting form
     * @param evt
     */
    onSubmitForm = (evt) => {
        evt.preventDefault();
        if(fv.isValidForm(this.state, this.dialog)) {
            mh.enviarForm(this.cotizarFormRef.current, this.dialog);
        }
    }
    render() {
        return (
            <div className={"form-box shadow-lg width-75 marl-125 mt-5 mb-4 ptb-5 pad-lr-5"}>
                <form ref={this.cotizarFormRef} onSubmit={this.onSubmitForm.bind(this)}>
                    <General updateState={this.updateState} />
                    <div className={"row"}>
                        <div className={"col-sm-5"}>
                            <Presentacion updateState={this.updateState} cleanFuelle={this.cleanUpFuelleFields}/>
                            <Especificaciones updateState={this.updateState} />
                            <Material updateState={this.updateState} />
                            <Aditivos updateState={this.updateState} />
                            <Impresion updateState={this.updateState} />
                        </div>
                        <div className={"col-sm-7"}>
                            <ImagePreview refer={this.imagePreviewDiv} />
                            <Container maxWidth={"sm"} className={"form-group btn-submit pt-5"}>
                                <button type="submit" className="btn btn-primary shadow-lg">{L.FORM.ENVIAR}</button>
                            </Container>
                            <WarningDialog setDialog={this.setDialog}/>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default CotizarForm;