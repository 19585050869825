import React, {Component} from "react";
import fuelleImgSQ from "../../../assets/preview/sello-fondo-cuadro.png";
import BolsaSueltaSelloLateralTitle from "../title-preview/bolsa-suelta-sello-lateral-title";
import SecondImageSelloLateralCuadro from "../second-preview/second-image-sello-lateral-cuadro";
import AperturaBolsa from "../title-preview/apertura-bolsa";

class SelloCuadroLateral extends Component {
    render() {
        const jsonObj = this.props.jsonObj;

        return (
            <div className={"iso-cuadrado"}>
                <BolsaSueltaSelloLateralTitle jsonObj={jsonObj} />
                <div className={"preview-image pt-5"}>
                    <div> {/* FIRST IMAGE */}
                        <AperturaBolsa />
                        <div style={{marginTop:"-20px"}}>
                            <span>{jsonObj.dimensiones.largo} {jsonObj.dimensiones.unidad}</span>
                            <img src={ fuelleImgSQ } alt={"bolsa con fuelle"}/>
                            <span style={{position:"relative", top:"112px"}}>{jsonObj.dimensiones.fuelle} {jsonObj.dimensiones.unidad}</span>
                        </div>
                        <div style={{marginTop:"-15px"}}>{jsonObj.dimensiones.ancho} {jsonObj.dimensiones.unidad}</div>
                    </div>
                    <SecondImageSelloLateralCuadro jsonObj={jsonObj}/>
                </div>
            </div>
        );
    }
}

export default SelloCuadroLateral;