import React, { Component } from "react";

import facebookIcon from "../../assets/icon/facebook-bco.png";
import instagramIcon from "../../assets/icon/instagram-bco.png";
import linkedInIcon from "../../assets/icon/linkedIn-bco.png";
import locationIcon from "../../assets/icon/ubicacion_bco.png";
import deliveryIcon from "../../assets/icon/seguro_bco.png";
import adesFooterLogo from "../../assets/icon/ades-footer-logo.png";
import L from "../i18n/label.json";
import M from "../i18n/msg.json";

class Footer extends Component {
    render() {
        return (
            <div className="footer-atmx container-fluid bg-blue text-light has-height-md middle-items border-top text-center wow fadeIn">
                <div className="row">
                    <div className="col-sm-2">
                        <img src={adesFooterLogo} className="footer-logo" alt={L.EMPRESA.NOMBRE}/>
                    </div>
                    <div className="col-sm-3">
                        <div className="row">
                            <div className="col-sm-2">
                                <img src={locationIcon} className="footer-icon" alt={L.FOOTER.UBICA}/>
                            </div>
                            <div className="col-sm-10">
                                <h6>{L.FOOTER.UBICA}</h6>
                                <p className="text-muted">
                                    <a href={"https://maps.app.goo.gl/8kbmkzZgdsYQsW5T8"}
                                       target={"_blank"} rel={"noreferrer"}>{L.EMPRESA.DOMIC}
                                    </a>
                                    <br/>{L.EMPRESA.TEL}
                                    <br/><a href={L.EMPRESA.MAILTO}>{L.EMPRESA.MAIL}</a>
                                    <br/>{L.EMPRESA.HORA}</p>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <a href={"https://www.facebook.com/grupoadesmexico"} target={"_blank"} rel={"noreferrer"}>
                                            <img src={facebookIcon} className="footer-icon" alt={L.R_SOCIALES.FB} />
                                        </a>
                                    </div>
                                    <div className="col-sm-2">
                                        <a href={"https://instagram.com/grupo_adesmexico"} target={"_blank"} rel={"noreferrer"}>
                                            <img src={instagramIcon} className="footer-icon" alt={L.R_SOCIALES.INSTA} />
                                        </a>
                                    </div>
                                    <div className="col-sm-2">
                                        <a href={"https://www.linkedin.com/company/grupo-ades-de-mexico/"} target={"_blank"} rel={"noreferrer"}>
                                            <img src={linkedInIcon} className="footer-icon" alt={L.R_SOCIALES.IN} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="row">
                            <div className="col-sm-2">
                                <img src={deliveryIcon} className="footer-icon" alt="Envíos"/>
                            </div>
                            <div className="col-sm-10">
                                <h6>{L.FOOTER.ENVIO}</h6>
                                <p className="text-muted">{M.ENVIOS}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <p className="text-muted aviso">{L.FOOTER.AVISO}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;