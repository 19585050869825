import React, { Component } from "react";

class WarningAlert extends Component {

    render() {
        const _self = this;
        function closeAlert() {
            _self.props.parent.unmountAlertIfPresent();
        }

        return(
            <div id={"warningAlertID"} className="alert alert-warning alert-dismissible fade show" role="alert">
                {this.props.alertText}
                <button type="button" className="close" onClick={closeAlert} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        );
    }
}
export default WarningAlert;