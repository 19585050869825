import React, { Component } from "react";
import emailjs from "@emailjs/browser";
import mailConfig from "../i18n/mail-config.json";
import L from "../i18n/label.json";
import M from "../i18n/msg.json";
import DangerAlert from "../common/alerts/danger-alert";
import ReactDOM from "react-dom/client";
import SuccessAlert from "../common/alerts/success-alert";
import WarningAlert from "../common/alerts/warning-alert";
import TextField from "@mui/material/TextField";
import {FormControl} from "@mui/material";
import sv from "../validator/string-validator";

class ContactForm extends Component {

    _form = React.createRef();
    alertDomNode = null;
    parentDivId = "alertDiv";

    /**
     * initializing components after mounting
     */
    componentDidMount() {
        this.alertDomNode = ReactDOM.createRoot(document.getElementById(this.parentDivId));
    }

    /**
     *  this function is to prevent error when displaying an Alert
     */
    unmountAlertIfPresent() {
        if(document.getElementById(this.parentDivId).children.length > 0) {
            this.alertDomNode.unmount();
            this.alertDomNode = ReactDOM.createRoot(document.getElementById(this.parentDivId));
        }
    }

    render() {
        const self = this;

        /**
         * onChange Name validation
         * @param evt
         */
        function onChangeName(evt) {
            let _str = evt.target.value;
            if(!sv.isValidName(_str)) {
                evt.currentTarget.classList.add("error-field");
            } else {
                evt.currentTarget.classList.remove("error-field");
            }
        }

        /**
         * onChange Mail validation
         * @param evt
         */
        function onChangeMail(evt) {
            let _str = evt.target.value;
            if(!sv.isValidMail(_str)) {
                evt.currentTarget.classList.add("error-field");
            } else {
                evt.currentTarget.classList.remove("error-field");
            }
        }

        /**
         * validate entire form
         * @param _formValues
         * @returns {boolean}
         */
        function isValidForm(_formValues) {

            if( sv.isNullOrEmpty(_formValues[0].value) ||
                sv.isNullOrEmpty(_formValues[1].value) ||
                sv.isNullOrEmpty(_formValues[2].value)) {
                self.unmountAlertIfPresent();
                self.alertDomNode.render( <WarningAlert parent={self} alertText={M.ALERT.EMPTY_FIELDS}/> );
                return false;

            } else if(  !sv.isValidName(_formValues[0].value) ||
                        !sv.isValidMail(_formValues[1].value) ) {
                self.unmountAlertIfPresent();
                self.alertDomNode.render( <DangerAlert parent={self} alertText={M.ALERT.ERROR_FORM}/> );
                return false;

            } else {
                return true;
            }
        }

        /**
         * a function to send mail
         */
        function sendMail() {

            emailjs.sendForm(
                mailConfig.CONFIG.SERVICE_ID,
                mailConfig.TEMPLATES.CONTACTO,
                self._form.current,
                mailConfig.CONFIG.PUBLIC_KEY
            ).then((result) => {
                console.log(result.text);
                self.unmountAlertIfPresent();
                self.alertDomNode.render( <SuccessAlert parent={self} alertText={ M.ALERT.MAIL_SENT + " [" + result.text + "]" }/> );

            }, (error) => {
                console.log(error.text);
                self.unmountAlertIfPresent();
                self.alertDomNode.render( <DangerAlert parent={self} alertText={M.ALERT.MAIL_ERROR + " [" + error.text + "]"}/> );
            });
        }

        /**
         * handler for submitting form
         * @param evt
         */
        function onSubmitClick (evt) {
            evt.preventDefault();

            let _formValues = self._form.current;
            if(isValidForm(_formValues)) {
                sendMail();

                /* clearing out fields */
                _formValues[0].value = "";
                _formValues[1].value = "";
                _formValues[2].value = "";
            }
        }

        return(
            <div className="col-md-6 has-height-lg middle-items">
                <div className="row title-right marl-5">
                    <div className="col-sm-8">
                        <hr className="hr-line-left"/>
                    </div>
                    <div className="col-sm-4">
                        <h2>{this.props.titleName}</h2>
                    </div>
                </div>
                <div id={self.parentDivId}></div>
                <div className="form-box shadow-lg mar-35lr">
                    <form ref={self._form}>
                        <div className="form-group">
                            <FormControl fullWidth={true}>
                                <TextField id="form_home_nombre" name="from_name" onChange={onChangeName} defaultValue="" label={L.FORM.NOMBRE} variant="standard" />
                            </FormControl>
                        </div>
                        <div className="form-group">
                            <FormControl fullWidth={true}>
                                <TextField id="form_home_email" name="from_email" onChange={onChangeMail} defaultValue="" label={L.FORM.CORREO} variant="standard" />
                            </FormControl>
                        </div>
                        <div className="form-group mt-4">
                            <FormControl fullWidth={true}>
                                <TextField  id="messageId" name="message" label={L.FORM.COMENTA} placeholder={L.FORM.COMENTA} multiline={true} rows={7} />
                            </FormControl>
                        </div>
                        <div className="form-group btn-submit">
                            <button type="submit" className="btn btn-primary" onClick={onSubmitClick}>{L.FORM.ENVIAR}</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default ContactForm;