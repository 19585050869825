import React, {Component} from "react";
import fuelleImgRV from "../../../assets/preview/sello-fondo-vertical.png";
import BolsaSueltaSelloLateralTitle from "../title-preview/bolsa-suelta-sello-lateral-title";
import SecondImageSelloLateralVertical from "../second-preview/second-image-sello-lateral-vertical";
import AperturaBolsa from "../title-preview/apertura-bolsa";

class SelloVerticalLateral extends Component {
    render() {
        const jsonObj = this.props.jsonObj;

        return (
            <div className={"iso-vertical"}>
                <BolsaSueltaSelloLateralTitle jsonObj={jsonObj} />
                <div className={"preview-image pt-5"}>
                    <div> {/* FIRST IMAGE */}
                        <AperturaBolsa />
                        <div style={{marginTop:"-30px"}}>
                            <span>{jsonObj.dimensiones.largo} {jsonObj.dimensiones.unidad}</span>
                            <img src={ fuelleImgRV } alt={"bolsa con fuelle"}/>
                            <span style={{position:"relative", top:"170px"}}>{jsonObj.dimensiones.fuelle} {jsonObj.dimensiones.unidad}</span>
                        </div>
                        <div style={{marginTop:"-12px"}}>{jsonObj.dimensiones.ancho} {jsonObj.dimensiones.unidad}</div>
                    </div>
                    <SecondImageSelloLateralVertical jsonObj={jsonObj}/>
                </div>
            </div>
        );
    }
}

export default SelloVerticalLateral;