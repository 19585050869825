import sv from "./string-validator";
import M from "../i18n/msg.json";
import L from "../i18n/label.json";

const hasEmptyFields = (state) => {
    return (
        sv.isNullOrEmpty(state.form_gral_nombre) ||
        sv.isNullOrEmpty(state.form_gral_empresa) ||
        sv.isNullOrEmpty(state.form_gral_correo) ||
        sv.isNullOrEmpty(state.form_gral_tel));
}

const isAnchoValid = (state) => {
    return (sv.isValidDecimal(state.form_specs_ancho) &&
        sv.isNotNullOrEmpty(state.form_specs_ancho_unit));
}

const isLargoValid = (state) => {
    return (sv.isValidDecimal(state.form_specs_largo) &&
        sv.isNotNullOrEmpty(state.form_specs_largo_unit));
}

const isFuelleValid = (state) => {
    if(sv.isNullOrEmpty(state.form_specs_fuelle)) {
        return true;
    } else return (
        sv.isValidDecimal(state.form_specs_fuelle) &&
        sv.isNotNullOrEmpty(state.form_specs_fuelle_unit));
}

const isFuelleX2GreaterThanWidth = (state) => {
    return state.form_present_sello === L.FORM.PRESENT.S_FONDO &&
        (state.form_specs_fuelle * 2) > state.form_specs_ancho;
}

const isCalibreValid = (state) => {
    if(sv.isNullOrEmpty(state.form_specs_calibre)) {
        return true;
    } else return (
        sv.isValidDecimal(state.form_specs_calibre) &&
        sv.isNotNullOrEmpty(state.form_specs_calibre_unit));
}

const formValidator = {
    isValidForm: function (state, dialog) {
        if(hasEmptyFields(state)) {
            dialog.openDialog(true, "W", "", M.ALERT.GRAL_EMPTY_FIELDS);

        } else if(!sv.isValidMail(state.form_gral_correo)) {
            dialog.openDialog(true, "E", "", M.ALERT.WRONG_MAIL);

        } else if(!isAnchoValid(state)) {
            dialog.openDialog(true, "W", "", M.ALERT.WRONG_ANCHO);

        } else if(!isLargoValid(state)) {
            dialog.openDialog(true, "W", "", M.ALERT.WRONG_LARGO);

        } else if(!isFuelleValid(state)) {
            dialog.openDialog(true, "W", "", M.ALERT.WRONG_FUELLE);

        } else if(isFuelleX2GreaterThanWidth(state)) {
            dialog.openDialog(true, "W", "", M.ALERT.BIG_FUELLE);

        } else if(!isCalibreValid(state)) {
            dialog.openDialog(true, "W", "", M.ALERT.WRONG_CALIBRE);

        } else { // send mail
            console.info("all validations are OK");
            return true;

        } return false;
    }
}

export default formValidator;