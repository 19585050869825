import React, { Component } from "react";
import L from "../../i18n/label.json";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";

class Presentacion extends Component {

    constructor(params) {
        super(params);
        this.state = {
            presentBL : "",
            presentSR : "",
            presentSello : ""
        }
    }

    onInputChange = (evt) => {
        this.props.updateState({
            [evt.target.name] : evt.target.value
        });
    }

    onInputChangeBL = (evt) => {
        this.setState({
            presentBL : evt.target.value
        });

        if(evt.target.value === L.FORM.PRESENT.LAMINA) {
            document.getElementById("fuelleDivId").style.display = "none";
            document.getElementById("form_div_present_sello").style.display = "none";
            this.setState({ presentSello : "" });
            this.props.updateState({
                "form_present_sello" : "",
                "form_specs_fuelle" : "",
                "form_specs_fuelle_unit" : ""
            });
            console.info(this.props.ob)
            this.props.cleanFuelle();

        } else {
            document.getElementById("fuelleDivId").style.display = "flex";
            //only displaying when 'bolsa en suelta' is selected
            if(this.state.presentSR === L.FORM.PRESENT.SUELTA) {
                document.getElementById("form_div_present_sello").style.display = "flex";

            } else {
                document.getElementById("form_div_present_sello").style.display = "none";
                this.setState({ presentSello : "" });
                this.props.updateState({
                    "form_present_sello" : "",
                    "form_specs_fuelle" : "",
                    "form_specs_fuelle_unit" : ""
                });
                this.props.cleanFuelle();
            }
        } this.onInputChange(evt);
    }

    onInputChangeSR = (evt) => {
        this.setState({
            presentSR : evt.target.value
        });

        if(evt.target.value === L.FORM.PRESENT.SUELTA) {
            //only displaying when 'bolsa en suelta' is selected
            if(this.state.presentBL === L.FORM.PRESENT.BOLSA) {
                document.getElementById("form_div_present_sello").style.display = "flex";

            } else {
                document.getElementById("form_div_present_sello").style.display = "none";
                this.props.updateState({ "form_present_sello" : "" });
                this.setState({ presentSello : "" });
            }
        } else {
            document.getElementById("form_div_present_sello").style.display = "none";
            this.props.updateState({ "form_present_sello" : "" });
            this.setState({ presentSello : "" });
        } this.onInputChange(evt);
    }

    onInputChangeSello = (evt) => {
        this.setState({
            presentSello : evt.target.value
        });
        this.onInputChange(evt);
    }

    render() {
        return (
            <div className={"ptb-5"}>
                <div className="form-group align-start">
                    <h4>{L.FORM.SECCION.PRESEN}</h4>
                </div>

                <div className="row form-present-box"> {/******* BOLSA - LAMINA *******/}
                    <div className="col-sm-12 pad-lr-0">
                        <RadioGroup row={true} name="form_present_bl" value={this.state.presentBL} onChange={this.onInputChangeBL}>
                            <FormControlLabel value={L.FORM.PRESENT.BOLSA} control={<Radio />} label={L.FORM.PRESENT.BOLSA} />
                            <FormControlLabel value={L.FORM.PRESENT.LAMINA} control={<Radio />} label={L.FORM.PRESENT.LAMINA} />
                        </RadioGroup>
                    </div>
                </div>

                <div className="row pt-3"> {/******* SUELTA - ROLLO *******/}
                    <div className="col-sm-12 pad-lr-0">
                        <div className="row">
                            <div className="col-sm-7 pad-lr-0 pt-2">
                                <RadioGroup row={true} name="form_present_sr" value={this.state.presentSR} onChange={this.onInputChangeSR}>
                                    <FormControlLabel value={L.FORM.PRESENT.SUELTA} control={<Radio />} label={L.FORM.PRESENT.SUELTA} />
                                    <FormControlLabel value={L.FORM.PRESENT.ROLLO} control={<Radio />} label={L.FORM.PRESENT.ROLLO} />
                                </RadioGroup>
                            </div>
                            <div className="col-sm-5 pad-lr-0">
                                <TextField name="form_present_otro" defaultValue="" onChange={this.onInputChange} label={L.FORM.PRESENT.OTRO} variant="standard" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id={"form_div_present_sello"} style={{display:"none"}} className={"p-25 mt-4 mr-5 form-sellos"}> {/******* SELLOS *******/}
                    <RadioGroup row={true} name="form_present_sello" value={this.state.presentSello} onChange={this.onInputChangeSello}>
                        <FormControlLabel value={L.FORM.PRESENT.S_FONDO} control={<Radio />} label={L.FORM.PRESENT.S_FONDO} />
                        <FormControlLabel value={L.FORM.PRESENT.S_LAT} control={<Radio />} label={L.FORM.PRESENT.S_LAT} />
                    </RadioGroup>
                </div>
            </div>
        );
    }
}

export default Presentacion;