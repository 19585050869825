import React, { Component } from "react";

class CoverThree extends Component {
    render() {
        return(
            <header id="3rdCoverId" className="header header-bg-cover-3">
                <div className="overlay"></div>
            </header>
        );
    }
}

export default CoverThree;