import React, { Component } from "react";
import L from "../../i18n/label.json";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";

class Aditivos extends Component {

    onInputChange = (evt) => {
        let value = evt.target.value;

        if(evt.target.type === "checkbox") {
            value = evt.target.checked;
        }

        this.props.updateState({
            [evt.target.name] : value
        });
    }

    render() {
        return (
            <div>
                <div className="form-group align-start">
                    <h4>{L.FORM.SECCION.ADITIV}</h4>
                </div>
                <div className="row">
                    <div className="col-sm-3 pad-lr-0 pt-2">
                        <div className="row">
                            <div className="col-sm-6 pad-lr-0">
                                <FormControlLabel name="form_aditivo_na" onChange={this.onInputChange} control={<Checkbox />} label={L.FORM.UNITS.NA} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-8 pad-lr-0 text-left">
                        <TextField name="form_aditivo_otro" onChange={this.onInputChange} label={L.FORM.PRESENT.OTRO} variant="standard" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Aditivos;