import React, { Component } from "react";
import Navigation from "../common/navigation";
import Company from "./company";
import Vision from "./vision";
import Mission from "./mission";
import OurValues from "./our-values";
import Footer from "../common/footer";

class NosotrosPage extends Component {
    render() {
        return(
            <div className="App">
                <Navigation lightFont={true} />
                <Company/>
                <Mission/>
                <Vision/>
                <OurValues/>
                <Footer/>
            </div>
        );
    }
}

export default NosotrosPage;