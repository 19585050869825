import React, { Component } from "react";
import TitleCenterWide from "../common/title-center-wide";
import L from "../i18n/label.json";

class VariableFactor extends Component {
    render() {
        return (
            <div className={"ptb-5"}>
                <TitleCenterWide customTitle={L.TITULO.VAR_FACT}/>
                <div className="container-fluid wow fadeIn" id="missionSectionId" data-wow-duration="1.5s">
                    <div className="row p-20">
                        <div className="col-lg-5">
                            <div className="row">
                                <div className="col-sm-12 pt-5 product">
                                    <ul>
                                        <li>Resina de Polietileno de baja densidad.</li>
                                        <li>Resinas de alto desempeño (mejoran las propiedades mecánicas y ópticas del empaque).</li>
                                        <li>Aditivos a partir de ceras y minerales (Antibloqueantes, deslizantes).</li>
                                        <li>Tintas (pigmentos, resinas base para las tintas, solventes, dióxido de titanio y aditivos químicos).</li>
                                        <li>Solventes.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 has-img-variables"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VariableFactor;