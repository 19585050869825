import React, {Component} from "react";
import {Container} from "@mui/material";

class ImagePreview extends Component {
    render() {
        return(
            <Container maxWidth={"sm"} ref={this.props.refer} className={"imagePlaceholder"}></Container>
        );
    }
}

export default ImagePreview;