import emailjs from "@emailjs/browser";
import mail from "../i18n/mail-config.json";
import M from "../i18n/msg.json";

const refresh = () => window.location.reload();

const mailHelper = {

    enviarCorreo : function (jsonData, dialog) {
        emailjs.send(
            mail.CONFIG.SERVICE_ID,
            mail.TEMPLATES.COTIZAR,
            jsonData,
            mail.CONFIG.PUBLIC_KEY

        ).then(function (response) {
            console.log('SUCCESS!', response.status, response.text);
            dialog.openDialog(true, "S", response.text, M.ALERT.MAIL_SENT);

        }, function (error) {
            console.error('FAILED...', error);
            dialog.openDialog(true, "E", "Error", M.ALERT.MAIL_ERROR);
        });
    },

    enviarForm : function (form, dialog) {
        emailjs.sendForm(
            mail.CONFIG.SERVICE_ID,
            mail.TEMPLATES.COTIZAR,
            form,
            mail.CONFIG.PUBLIC_KEY

        ).then(function (response) {
            console.log('SUCCESS!', response.status, response.text);
            dialog.openDialog(true, "S", response.text, M.ALERT.MAIL_SENT, refresh);

        }, function (error) {
            console.error('FAILED...', error);
            dialog.openDialog(true, "E", "Error", M.ALERT.MAIL_ERROR);
        });
    }
}

export default mailHelper;