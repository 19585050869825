import React, { Component } from "react";

class Company extends Component {
    render() {
        return(
            <div className="container-fluid wow fadeIn" id="missionSectionId" data-wow-duration="1.5s">
                <div className="row p-20 mart-15">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-sm-12 pt-4">
                                <p className="justify pl-30 pr-30">
                                    Somos una empresa 100% mexicana con mas de 25 años de experiencia posicionada en
                                    diferentes industrias.<br/><br/>Nuestra experiencia se basa en cubrir las necesidades
                                    de empaques flexibles creándole un sentido de pertenencia y desarrollando los proyectos
                                    con forme se requieran.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 has-img-company"></div>
                </div>
            </div>
        );
    }
}

export default Company;