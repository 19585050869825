import React, { Component } from "react";
import L from "../i18n/label.json";

class Products extends Component {
    render() {
        return(
            <div className="container-fluid wow fadeIn ptb-5" id="productsSectionId" data-wow-duration="1.5s">
                <div className="row p-20">
                    <div className="col-lg-6">
                        <div className="row title-left marr-5">
                            <div className="col-sm-5">
                                <h2>{L.TITULO.PRODUCTOS}</h2>
                            </div>
                            <div className="col-sm-7">
                                <hr className="hr-line-right"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="justify pl-30 pr-30">
                                    Con la implementación de nuestra alta tecnología optimizamos los recursos necesarios y
                                    con ello existe un menor consumo de energía y agua, proporcionando productos sustentables.
                                    <br/><br/>Implementamos tecnología de vanguardia en el desarrollo de nuestros productos,
                                    optimizando los recursos para un menor consumo de energía y agua, fomentando así la
                                    sustentabilidad que nos proyecta como una empresa que cuida el planeta y a las siguientes
                                    generaciones.<br/><br/>Como resultado de la alta calidad de estos procesos, nuestros
                                    productos obtienen la preferencia de la sociedad y así se consolida el crecimiento de
                                    la empresa.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 has-img-products"></div>
                </div>
            </div>
        );
    }
}

export default Products;