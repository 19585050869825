import React, { Component } from "react";
import { Outlet, Link } from "react-router-dom";
import L from "../i18n/label.json";

import wFacebookIcon from "../../assets/icon/facebook-bco.png";
import bFacebookIcon from "../../assets/icon/facebook-black.png";
import wInstagramIcon from "../../assets/icon/instagram-bco.png";
import bInstagramIcon from "../../assets/icon/instagram-black.png";
import wLinkedInIcon from "../../assets/icon/linkedIn-bco.png";
import bLinkedInIcon from "../../assets/icon/linkedin-black.png";
import wPhoneCallIcon from "../../assets/icon/phone-call-bco.png";
import bPhoneCallIcon from "../../assets/icon/phone-call-black.png";
import adesHeaderLogo from "../../assets/icon/ades-header-logo.png";

class Navigation extends Component {
    render() {
        function getClassName (isLightFont) {
            let _class = "custom-navbar navbar navbar-expand-lg fixed-top navbar";
            if(isLightFont) {
                _class += "-light";
            } else {
                _class += "-dark";
            }
            return _class;
        }

        return (
            <>
                <nav className={getClassName(this.props.lightFont)}>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <a className="navbar-brand m-auto" href="/">
                            <img src={adesHeaderLogo} className="brand-img" alt={L.EMPRESA.ALT} />
                        </a>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link className="nav-link" to={L.MENU.INICIO.URL}>
                                    {L.MENU.INICIO.NAME}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={L.MENU.NOSOTROS.URL}>
                                    {L.MENU.NOSOTROS.NAME}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={L.MENU.PRODUCTOS.URL}>
                                    {L.MENU.PRODUCTOS.NAME}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={L.MENU.COTIZA.URL}>
                                    {L.MENU.COTIZA.NAME}
                                </Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <div className="row">
                                    <div className="col-sm-2 pt5">
                                        <img src={ this.props.lightFont ? bPhoneCallIcon : wPhoneCallIcon }
                                             className="footer-icon" alt={L.R_SOCIALES.PHONE} />
                                    </div>
                                    <div className="col-sm-10">
                                        <a href={L.EMPRESA.TEL_LINK}>
                                            <label className="nav-link">{L.EMPRESA.TEL}</label>
                                        </a>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item">
                                <div className="row">
                                    <div className="col-sm-3 pt5">
                                        <a href={"https://www.facebook.com/grupoadesmexico"} target={"_blank"} rel={"noreferrer"}>
                                            <img src={ this.props.lightFont ? bFacebookIcon : wFacebookIcon }
                                                 className="header-icon" alt={L.R_SOCIALES.FB} />
                                        </a>
                                    </div>
                                    <div className="col-sm-3 pt5">
                                        <a href={"https://instagram.com/grupo_adesmexico"} target={"_blank"} rel={"noreferrer"}>
                                            <img src={ this.props.lightFont ? bInstagramIcon : wInstagramIcon }
                                                 className="header-icon" alt={L.R_SOCIALES.INSTA} />
                                        </a>
                                    </div>
                                    <div className="col-sm-3 pt5">
                                        <a href={"https://www.linkedin.com/company/grupo-ades-de-mexico/"} target={"_blank"} rel={"noreferrer"}>
                                            <img src={ this.props.lightFont ? bLinkedInIcon : wLinkedInIcon }
                                                 className="header-icon" alt={L.R_SOCIALES.IN} />
                                        </a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
                <Outlet/>
            </>
        );
    }
}

export default Navigation;