import React, { Component } from "react";
import L from "../i18n/label.json";
import M from "../i18n/msg.json";

class Vision extends Component {
    render() {
        return(
            <div className="container-fluid wow fadeIn" id="missionSectionId" data-wow-duration="1.5s">
                <div className="row p-20">
                    <div className="col-lg-6">
                        <div className="row title-left marr-5">
                            <div className="col-sm-4">
                                <h2>{L.TITULO.VISION}</h2>
                            </div>
                            <div className="col-sm-8">
                                <hr className="hr-line-right"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="justify pl-30 pr-30 bulb-bg">{M.VISION}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 has-img-vision"></div>
                </div>
            </div>
        );
    }
}

export default Vision;