import React, { Component } from "react";

class NotFound404 extends Component {
    render() {
        return (
            <>
                <h2>Page Not Found 404</h2>
            </>
        );
    }
}

export default NotFound404;