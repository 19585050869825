import React, { Component } from "react";

import TitleCenter from "../common/title-center";
import RBCarousel from "react-bootstrap-carousel";
import Card from "../common/card";

import L from "../i18n/label.json";
import M from "../i18n/msg.json";

class OurValues extends Component {
    render() {
        return(
            <div className={"ptb-5"}>
                <TitleCenter customTitle={L.TITULO.VALORES}/>
                <RBCarousel
                    animation={true}
                    autoplay={true}
                    slideshowSpeed={7000}
                    defaultActiveIndex={0}
                    leftIcon={"❮"}
                    rightIcon={"❯"}
                    version={4}>

                    <div className={"row card-row"}>
                        <Card titleText={L.VALORES.INTEGRIDAD} bodyText={M.VALORES.INTEGRIDAD} />
                        <Card titleText={L.VALORES.ETICA} bodyText={M.VALORES.ETICA} />
                        <Card titleText={L.VALORES.HONESTIDAD} bodyText={M.VALORES.HONESTIDAD} />
                    </div>
                    <div className={"row card-row"}>
                        <Card titleText={L.VALORES.ENTREGA} bodyText={M.VALORES.ENTREGA} />
                        <Card titleText={L.VALORES.CONFIABLES} bodyText={M.VALORES.CONFIABLES} />
                        <Card titleText={L.VALORES.DEDICACION} bodyText={M.VALORES.DEDICACION} />
                    </div>
                    <div className={"row card-row"}>
                        <Card titleText={L.VALORES.RESPONSABILIDAD} bodyText={M.VALORES.RESPONSABILIDAD} />
                        <Card titleText={L.VALORES.TENACIDAD} bodyText={M.VALORES.TENACIDAD} />
                        <Card titleText={L.VALORES.RESPETO} bodyText={M.VALORES.RESPETO} />
                    </div>
                    <div className={"row card-row"}>
                        <Card titleText={L.VALORES.T_EQUIPO} bodyText={M.VALORES.T_EQUIPO} />
                        <Card titleText={L.VALORES.C_RESPUESTA} bodyText={M.VALORES.C_RESPUESTA} />
                    </div>
                </RBCarousel>
            </div>
        );
    }
}

export default OurValues;