import React, {Component} from "react";
import fuelleImgRH from "../../../assets/preview/fuelle-rectangulo-horizontal.png";
import SecondImageHorizontal from "../second-preview/second-image-horizontal";
import BolsaSueltaRolloSelloFondoTitle from "../title-preview/bolsa-suelta-rollo-sello-fondo-title";
import AperturaBolsa from "../title-preview/apertura-bolsa";

class IsoHorizontal extends Component {
    render() {
        const jsonObj = this.props.jsonObj;

        return (
            <div className={"iso-horizontal"}>
                <BolsaSueltaRolloSelloFondoTitle jsonObj={jsonObj} />
                <div className={"preview-image pt-5"}>
                    <div> {/* FIRST IMAGE */}
                        <AperturaBolsa style={{paddingLeft:"65px"}}/>
                        <div>
                            <span style={{marginLeft:"12%"}}>{jsonObj.dimensiones.fuelle} {jsonObj.dimensiones.unidad}</span>
                            <span style={{paddingLeft:"29%"}}>{jsonObj.dimensiones.fuelle} {jsonObj.dimensiones.unidad}</span>
                        </div>
                        <div>
                            <span>{jsonObj.dimensiones.largo} {jsonObj.dimensiones.unidad}</span>
                            <img src={ fuelleImgRH } alt={"bolsa con fuelle"}/>
                        </div>
                        <div style={{paddingLeft:"12%", marginTop:"-15px"}}>{jsonObj.dimensiones.ancho} {jsonObj.dimensiones.unidad}</div>
                    </div>
                    <SecondImageHorizontal jsonObj={jsonObj}/>
                </div>
            </div>
        );
    }
}

export default IsoHorizontal;