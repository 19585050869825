import React, {Component} from "react";

class BolsaLaminaTitle extends Component {
    render() {
        const jsonObj = this.props.jsonObj;

        return (
            <div>
                <h4>{jsonObj.specs.presentacion} {jsonObj.dimensiones.ancho} x {jsonObj.dimensiones.largo} {jsonObj.dimensiones.unidad}</h4>
            </div>
        );
    }
}

export default BolsaLaminaTitle;