import React, { Component } from "react";
import L from "../../i18n/label.json";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";

/**
 * This class defines all the measures
 */
class Especificaciones extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fuelleValue : "",
            radioFuelleUnit : "",
            radioAnchoUnit : "",
            radioLargoUnit : ""
        }
    }

    cleanUpFuelleFields = () => {
        this.setState({
            "fuelleValue" : "",
            "radioFuelleUnit" : L.FORM.UNITS.NA
        });
    }

    componentDidMount() {
        this.props.updateState({
            "cleanUpFuelleFields" : this.cleanUpFuelleFields
        })
    }

    onInputChange = (evt) => {
        this.props.updateState({
            [evt.target.name] : evt.target.value.toString()
        });
    }

    onUnitChange = (evt) => {
        let _unit = "";
        if(evt.target.value === L.FORM.UNITS.CM) {
            _unit = L.FORM.UNITS.CM;

        } else if(evt.target.value === L.FORM.UNITS.IN) {
            _unit = L.FORM.UNITS.IN;
        }

        if(this.state.radioFuelleUnit === L.FORM.UNITS.NA) {
            this.setState({
                radioAnchoUnit : _unit,
                radioLargoUnit : _unit
            });

            this.props.updateState({
                "form_specs_ancho_unit" : _unit,
                "form_specs_largo_unit" : _unit
            });

        } else {
            this.setState({
                radioAnchoUnit : _unit,
                radioLargoUnit : _unit,
                radioFuelleUnit : _unit
            });

            this.props.updateState({
                "form_specs_ancho_unit" : _unit,
                "form_specs_largo_unit" : _unit,
                "form_specs_fuelle_unit" : _unit
            });
        }
    }

    /**
     * onChange method for radio button
     * @param evt
     */
    onFuelleRadioChange = (evt) => {
        if(evt.target.value === L.FORM.UNITS.NA) {
            this.setState({
                fuelleValue : "",
                radioFuelleUnit : evt.target.value
            });
            this.onInputChange(evt);

        } else {
            this.setState({
                radioAnchoUnit : evt.target.value,
                radioLargoUnit : evt.target.value,
                radioFuelleUnit : evt.target.value
            });
            this.props.updateState({
                "form_specs_ancho_unit" : evt.target.value,
                "form_specs_largo_unit" : evt.target.value,
                "form_specs_fuelle_unit" : evt.target.value
            });
        }
    }

    /**
     * onChange method for Text Field
     * @param evt
     */
    onFuelleInputChange = (evt) => {
        if(this.state.radioFuelleUnit === L.FORM.UNITS.NA) {
            this.setState({
                fuelleValue : evt.target.value,
                radioFuelleUnit : ""
            });
            this.onInputChange({
                target: {
                    name : "form_specs_fuelle_unit",
                    value : ""
                }
            });
        } else {
            this.setState({
                fuelleValue : evt.target.value
            });
            this.props.updateState({
                "form_specs_fuelle" : evt.target.value
            });
        } this.onInputChange(evt);
    }

    render() {
        return (
            <div className={"text-left"}>
                <div className="form-group align-start">
                    <h4>{L.FORM.SECCION.ESPEC}</h4>
                </div>

                <div className="row"> {/***** ANCHO *****/}
                    <div className="col-sm-3 pl-0">
                        <TextField name="form_specs_ancho" defaultValue="" onChange={this.onInputChange} label={L.FORM.SPECIF.ANCHO} variant="standard" />
                    </div>
                    <div className="col-sm-9 pad-lr-0 pt-3">
                        <RadioGroup row={true} value={this.state.radioAnchoUnit} name="form_specs_ancho_unit" onChange={this.onUnitChange}>
                            <FormControlLabel value={L.FORM.UNITS.CM} control={<Radio />} label={L.FORM.UNITS.CM} />
                            <FormControlLabel value={L.FORM.UNITS.IN} control={<Radio />} label={L.FORM.UNITS.IN} />
                        </RadioGroup>
                    </div>
                </div>

                <div className="row pt-3"> {/***** LARGO *****/}
                    <div className="col-sm-3 pl-0">
                        <TextField name="form_specs_largo" defaultValue="" onChange={this.onInputChange} label={L.FORM.SPECIF.LARGO} variant="standard" />
                    </div>
                    <div className="col-sm-9 pad-lr-0 pt-3">
                        <RadioGroup row={true} value={this.state.radioLargoUnit} name="form_specs_largo_unit" onChange={this.onUnitChange}>
                            <FormControlLabel value={L.FORM.UNITS.CM} control={<Radio />} label={L.FORM.UNITS.CM} />
                            <FormControlLabel value={L.FORM.UNITS.IN} control={<Radio />} label={L.FORM.UNITS.IN} />
                        </RadioGroup>
                    </div>
                </div>

                <div id="fuelleDivId" className="row pt-3"> {/***** FUELLE *****/}
                    <div className="col-sm-3 pl-0">
                        <TextField name="form_specs_fuelle" value={this.state.fuelleValue}
                                   onChange={this.onFuelleInputChange} label={L.FORM.SPECIF.FUELLE} variant="standard" />
                    </div>
                    <div className="col-sm-9 pad-lr-0 pt-3">
                        <RadioGroup row={true} value={this.state.radioFuelleUnit} name="form_specs_fuelle_unit" onChange={this.onFuelleRadioChange}>
                            <FormControlLabel value={L.FORM.UNITS.CM} control={<Radio />} label={L.FORM.UNITS.CM} />
                            <FormControlLabel value={L.FORM.UNITS.IN} control={<Radio />} label={L.FORM.UNITS.IN} />
                            <FormControlLabel value={L.FORM.UNITS.NA} control={<Radio />} label={L.FORM.UNITS.NA} />
                        </RadioGroup>
                    </div>
                </div>

                <div className="row pt-3"> {/***** CALIBRE O GROSOR *****/}
                    <div className="col-sm-3 pl-0">
                        <TextField name="form_specs_calibre" defaultValue="" onChange={this.onInputChange} label={L.FORM.SPECIF.CALIBRE} variant="standard" />
                    </div>
                    <div className="col-sm-9 pad-lr-0 pt-3">
                        <RadioGroup row={true} name="form_specs_calibre_unit" onChange={this.onInputChange}>
                            <FormControlLabel value={L.FORM.UNITS.GAU} control={<Radio />} label={L.FORM.UNITS.GAU} />
                            <FormControlLabel value={L.FORM.UNITS.MM} control={<Radio />} label={L.FORM.UNITS.MM} />
                            <FormControlLabel value={L.FORM.UNITS.MIC} control={<Radio />} label={L.FORM.UNITS.MIC} />
                            <FormControlLabel value={L.FORM.UNITS.MIL} control={<Radio />} label={L.FORM.UNITS.MIL} />
                        </RadioGroup>
                    </div>
                </div>
            </div>
        );
    }
}

export default Especificaciones;