import React, { Component } from "react";
import ContactForm from "./contact-form";
import L from "../i18n/label.json";

class Contact extends Component {
    render() {
        return(
            <div id="contactSectionId" className="container-fluid wow fadeIn ptb-5">
                <div className="row p-20">
                    <div className="col-md-6 px-0">
                        <div id="map" style={{width: "100%", height: "100%", minHeight: "400px"}}>
                            <iframe title="Grupo Ades" width="100%" height="100%" style={{border:"0"}}
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1866.659523605961!2d-103.35628162959499!3d20.65659504016804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b3973d7aa517%3A0x5653d5fd434d6913!2sGrupo%20Ades!5e0!3m2!1ses-419!2smx!4v1695419271714!5m2!1ses-419!2smx"
                                    allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                            </iframe>
                        </div>
                    </div>
                    <ContactForm titleName={L.TITULO.CONTACTO}/>
                </div>
            </div>
        );
    }
}

export default Contact;