import React, { Component } from "react";
import TitleCenterWide from "../common/title-center-wide";
import L from "../i18n/label.json";

class OurProducts extends Component {
    render() {
        return (
            <div className={"ptb-5"}>
                <TitleCenterWide customTitle={L.TITULO.N_PRODUC}/>
                <div className="container-fluid wow fadeIn" id="missionSectionId" data-wow-duration="1.5s">
                    <div className="row p-20">
                        <div className="col-lg-5">
                            <div className="row">
                                <div className="col-sm-12 pt-5 product">
                                    <ul>
                                        <li>Bolsas, películas y sacos de polietileno y polipropileno.</li>
                                        <li>Impresión de 1 - 8 tintas en máquina stack.</li>
                                        <li>impresión de 1 - 10 tintas en máquina tambor central.</li>
                                        <li>Laminaciones LDPE-LDPE, LDEP- BOPP, BOPP-BOPP, LDPE-Poliéster</li>
                                        <li>Bolsas con aditivos (VCI, AE, UV, Congelación)</li>
                                        <li>
                                            <span>Bolsas 100% virgen:</span>
                                            <ul style={{ paddingLeft:"30px" }}>
                                                <li>Bolsas al vacío</li>
                                                <li>Bolsas zipper</li>
                                                <li>Bolsas para mensajería</li>
                                            </ul>
                                        </li>
                                        <li>Bolsas recicladas</li>
                                        <li>Bolsas Biodegradables</li>
                                        <li>Películas y sacos con diferente formulación de acuerdo al uso final.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 has-img-our-products"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OurProducts;