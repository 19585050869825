import React, { Component } from "react";

class CoverTwo extends Component {
    render() {
        return(
            <header id="2ndCoverId" className="header header-bg-cover-2">
                <div className="overlay"></div>
            </header>
        );
    }
}

export default CoverTwo;