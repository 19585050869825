import React, {Component} from "react";
import fuelleImgRH from "../../../assets/preview/sello-fondo-horizontal.png";
import BolsaSueltaSelloLateralTitle from "../title-preview/bolsa-suelta-sello-lateral-title";
import SecondImageSelloLateralHorizontal from "../second-preview/second-image-sello-lateral-horizontal";
import AperturaBolsa from "../title-preview/apertura-bolsa";

class SelloHorizontalLateral extends Component {
    render() {
        const jsonObj = this.props.jsonObj;

        return (
            <div className={"iso-horizontal"}>
                <BolsaSueltaSelloLateralTitle jsonObj={jsonObj} />
                <div className={"preview-image pt-5"}>
                    <div> {/* FIRST IMAGE */}
                        <AperturaBolsa />
                        <div style={{marginTop:"-35px"}}>
                            <span>{jsonObj.dimensiones.largo} {jsonObj.dimensiones.unidad}</span>
                            <img src={ fuelleImgRH } alt={"bolsa con fuelle"}/>
                            <span style={{position:"relative", top:"135px"}}>{jsonObj.dimensiones.fuelle} {jsonObj.dimensiones.unidad}</span>
                        </div>
                        <div style={{marginTop:"-15px"}}>{jsonObj.dimensiones.ancho} {jsonObj.dimensiones.unidad}</div>
                    </div>
                    <SecondImageSelloLateralHorizontal jsonObj={jsonObj}/>
                </div>
            </div>
        );
    }
}

export default SelloHorizontalLateral;