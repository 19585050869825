import React, { Component } from "react";
import L from "../../i18n/label.json";

import Radio from '@mui/material/Radio';
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from "@mui/material/TextField";

class Impresion extends Component {

    onInputChange = (evt) => {
        this.props.updateState({
            [evt.target.name] : evt.target.value
        });
    }

    onInputChangeSN = (evt) => {
        this.onInputChange(evt);
        if(evt.target.value === L.FORM.IMPRESION.SI) {
            document.getElementById("form_div_impression_num").style.display = "block";
        } else {
            document.getElementById("form_div_impression_num").style.display = "none";
        }
    }
    render() {
        return (
            <div className={"ptb-5"}>
                <div className="form-group align-start">
                    <h4>{L.FORM.SECCION.IMPRE}</h4>
                </div>

                <div className="row">
                    <div className="col-sm-5 pad-lr-0">
                        <RadioGroup row={true} name="form_impresion_sn" onChange={this.onInputChangeSN}>
                            <FormControlLabel value={L.FORM.IMPRESION.SI} control={<Radio />} label={L.FORM.IMPRESION.SI} />
                            <FormControlLabel value={L.FORM.IMPRESION.NO} control={<Radio />} label={L.FORM.IMPRESION.NO} />
                        </RadioGroup>
                    </div>
                </div>

                <div id="form_div_impression_num" style={{display:"none"}} className="row text-left">
                    <div className="col-sm-7 pad-lr-0">
                        <TextField name="form_impresion_num" defaultValue="" onChange={this.onInputChange} label={L.FORM.IMPRESION.NUM} variant="standard" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Impresion;