import React, { Component } from "react";
import L from "../../i18n/label.json";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";

class Material extends Component {

    onInputChange = (evt) => {
        this.props.updateState({
            [evt.target.name] : evt.target.value
        });
    }

    onInputChangePT = (evt) => {
        this.onInputChange(evt);
        if(evt.target.value === L.FORM.MATERIAL.PIGMEN) {
            document.getElementById("form_div_material_color").style.display = "block";
        } else {
            document.getElementById("form_div_material_color").style.display = "none";
        }
    }

    render() {
        return (
            <div className={"ptb-5"}>
                <div className="form-group align-start">
                    <h4>{L.FORM.SECCION.MATER}</h4>
                </div>

                <div className="row form-material-box"> {/******* ALTA - BAJA *******/}
                    <div className="col-sm-12 pad-lr-0">
                        <RadioGroup row={true} name="form_material_ab" onChange={this.onInputChange}>
                            <FormControlLabel value={L.FORM.MATERIAL.ALTA} control={<Radio />} label={L.FORM.MATERIAL.ALTA} />
                            <FormControlLabel value={L.FORM.MATERIAL.BAJA} control={<Radio />} label={L.FORM.MATERIAL.BAJA} />
                        </RadioGroup>
                    </div>
                </div>

                <div className="row pt-4"> {/******* PIGMENTACION - TRANSPARENTE *******/}
                    <div className="col-sm-12 pad-lr-0">
                        <RadioGroup row={true} name="form_material_pt" onChange={this.onInputChangePT}>
                            <FormControlLabel value={L.FORM.MATERIAL.PIGMEN} control={<Radio />} label={L.FORM.MATERIAL.PIGMEN} />
                            <FormControlLabel value={L.FORM.MATERIAL.TRANSP} control={<Radio />} label={L.FORM.MATERIAL.TRANSP} />
                        </RadioGroup>
                    </div>
                </div>

                <div id="form_div_material_color" style={{display:"none"}} className="row"> {/******* COLOR *******/}
                    <div className="col-sm-8 pad-lr-0">
                        <div className="row">
                            <div className="col-sm-12 pad-lr-0 text-left">
                                <TextField name="form_material_color" defaultValue="" onChange={this.onInputChange} label={L.FORM.MATERIAL.COLOR} variant="standard" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Material;