import React, {Component} from "react";
import fuelleImgSQ from "../../../assets/preview/fuelle-cuadrado.png";
import SecondImageCuadro from "../second-preview/second-image-cuadro";
import BolsaSueltaRolloSelloFondoTitle from "../title-preview/bolsa-suelta-rollo-sello-fondo-title";
import AperturaBolsa from "../title-preview/apertura-bolsa";

class IsoCuadrado extends Component {

    render() {
        const jsonObj = this.props.jsonObj;

        return (
            <div className={"iso-cuadrado"}>
                <BolsaSueltaRolloSelloFondoTitle jsonObj={jsonObj} />
                <div className={"preview-image pt-5"}>
                    <div> {/* FIRST IMAGE */}
                        <AperturaBolsa style={{paddingLeft:"60px"}}/>
                        <div>
                            <span style={{marginLeft:"12%"}}>{jsonObj.dimensiones.fuelle} {jsonObj.dimensiones.unidad}</span>
                            <span style={{paddingLeft:"14%"}}>{jsonObj.dimensiones.fuelle} {jsonObj.dimensiones.unidad}</span>
                        </div>
                        <div>
                            <span>{jsonObj.dimensiones.largo} {jsonObj.dimensiones.unidad}</span>
                            <img src={ fuelleImgSQ } alt={"bolsa con fuelle"}/>
                        </div>
                        <div style={{paddingLeft:"12%", marginTop:"-12px"}}>{jsonObj.dimensiones.ancho} {jsonObj.dimensiones.unidad}</div>
                    </div>
                    <SecondImageCuadro jsonObj={jsonObj}/>
                </div>
            </div>
        );
    }
}

export default IsoCuadrado;