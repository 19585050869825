import React, { Component } from "react";

class CoverOne extends Component {
    render() {
        return(
            <header id="1stCoverId" className="header header-bg-cover-1">
                <div className="overlay"></div>
            </header>
        );
    }
}

export default CoverOne;