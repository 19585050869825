import React, { Component } from "react";
import L from "../i18n/label.json";
import M from "../i18n/msg.json";

class Mission extends Component {
    render() {
        return(
            <div className="container-fluid wow fadeIn ptb-5" id="aboutSectionId" data-wow-duration="1.5s">
                <div className="row p-20">
                    <div className="col-lg-6 has-img-mission"></div>
                    <div className="col-lg-6">
                        <div className="row title-right marl-5">
                            <div className="col-sm-8">
                                <hr className="hr-line-left"/>
                            </div>
                            <div className="col-sm-4">
                                <h2>{L.TITULO.MISION}</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="justify pl-30 pr-30 target-bg">{M.MISION}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Mission;