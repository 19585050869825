import sv from "./string-validator";
import L from "../i18n/label.json";

import IsoCuadrado from "./preview/iso-cuadrado";
import IsoHorizontal from "./preview/iso-horizontal";
import IsoVertical from "./preview/iso-vertical";
import PlanoCuadro from "./preview/plano-cuadro";
import PlanoHorizontal from "./preview/plano-horizontal";
import PlanoVertical from "./preview/plano-vertical";
import SelloCuadroLateral from "./preview/sello-cuadro-lateral";
import SelloHorizontalLateral from "./preview/sello-horizontal-lateral";
import SelloVerticalLateral from "./preview/sello-vertical-lateral";

const imageValidator = {

    validatePreview: function (state) {
        const width = state.form_specs_ancho;
        const length = state.form_specs_largo;
        const fuelle = state.form_specs_fuelle;
        const unit = state.form_specs_ancho_unit;
        const calibre = state.form_specs_calibre;
        const calibreUnit = state.form_specs_calibre_unit;
        const selloType = state.form_present_sello;
        const presentacion = state.form_present_bl;

        let previewImage = {
            dimensiones : {
                ancho : "",
                largo : "",
                profundidad : "",
                unidad : "",
                calibre : "",
                calibreUnit: ""
            },
            specs : {
                tipoSello: "",
                presentacion : ""
            },
            imagenes : {
                fuelle : "",
                isom : "",
                plano : "",
                sello : ""
            }
        }

        if(sv.isNotNullOrEmpty(width)) {
            const nWidth = Number.parseFloat(width);
            previewImage.dimensiones.ancho = nWidth.toFixed(2).toString(); // width is always fixed
            previewImage.dimensiones.unidad = unit.toString();

            if(sv.isNotNullOrEmpty(length)) {
                const nLength = Number.parseFloat(length);
                const nFuelle = sv.isNullOrEmpty(fuelle) ? 0.0 : Number.parseFloat(fuelle);

                previewImage.dimensiones.fuelle = nFuelle.toFixed(2).toString();
                previewImage.dimensiones.alto = (nLength - (nFuelle * 2)).toFixed(2).toString();
                previewImage.dimensiones.largo = nLength.toFixed(2).toString();
                previewImage.dimensiones.profundidad = nFuelle > 0 ? (nFuelle * 2).toFixed(2).toString() : "";

                if (nWidth === nLength) {
                    if (nFuelle > 0) {
                        previewImage.imagenes.plano = L.IMAGES.EMPTY;
                        previewImage.imagenes.isom = L.IMAGES.ISO_CUBO;
                        previewImage.imagenes.fuelle = L.IMAGES.FUE_CUADRO;

                        if(sv.isNotNullOrEmpty(selloType)) {
                            previewImage.specs.tipoSello = selloType;
                            if(selloType === L.FORM.PRESENT.S_LAT) {
                                previewImage.imagenes.sello = L.IMAGES.SELLO_LAT_CUAD;
                            } else if(selloType === L.FORM.PRESENT.S_FONDO) {
                                previewImage.imagenes.sello = L.IMAGES.SELLO_FONDO_CUAD;
                            }
                        }

                    } else {
                        previewImage.imagenes.plano = L.IMAGES.PL_CUADRO;
                        previewImage.imagenes.isom = L.IMAGES.EMPTY;
                        previewImage.imagenes.fuelle = L.IMAGES.EMPTY;
                    }

                } else if (nWidth > nLength) {
                    if (nFuelle > 0) {
                        previewImage.imagenes.plano = L.IMAGES.EMPTY;
                        previewImage.imagenes.isom = L.IMAGES.ISO_PRISMH;
                        previewImage.imagenes.fuelle = L.IMAGES.FUE_RECH;

                        if(sv.isNotNullOrEmpty(selloType)) {
                            previewImage.specs.tipoSello = selloType;
                            if(selloType === L.FORM.PRESENT.S_LAT) {
                                previewImage.imagenes.sello = L.IMAGES.SELLO_LAT_HOR;
                            } else if(selloType === L.FORM.PRESENT.S_FONDO) {
                                previewImage.imagenes.sello = L.IMAGES.SELLO_FONDO_HOR;
                            }
                        }

                    } else {
                        previewImage.imagenes.plano = L.IMAGES.PL_RECH;
                        previewImage.imagenes.isom = L.IMAGES.EMPTY;
                        previewImage.imagenes.fuelle = L.IMAGES.EMPTY;
                    }

                } else if (nWidth < nLength) {
                    if (nFuelle > 0) {
                        previewImage.imagenes.plano = L.IMAGES.EMPTY;
                        previewImage.imagenes.isom = L.IMAGES.ISO_PRISMV;
                        previewImage.imagenes.fuelle = L.IMAGES.FUE_RECV;

                        if(sv.isNotNullOrEmpty(selloType)) {
                            previewImage.specs.tipoSello = selloType;
                           if(selloType === L.FORM.PRESENT.S_LAT) {
                               previewImage.imagenes.sello = L.IMAGES.SELLO_LAT_VER;
                           } else if(selloType === L.FORM.PRESENT.S_FONDO) {
                               previewImage.imagenes.sello = L.IMAGES.SELLO_FONDO_VER;
                           }
                        }

                    } else {
                        previewImage.imagenes.plano = L.IMAGES.PL_RECV;
                        previewImage.imagenes.isom = L.IMAGES.EMPTY;
                        previewImage.imagenes.fuelle = L.IMAGES.EMPTY;
                    }
                }
            }

            if(sv.isNotNullOrEmpty(presentacion)) {
                previewImage.specs.presentacion = presentacion.toString();
            }

            if(sv.isNotNullOrEmpty(calibre) && sv.isNotNullOrEmpty(calibreUnit)) {
                previewImage.dimensiones.calibre = "Cal. ( " + calibre.toString() + " " + calibreUnit.toString() + " )";
            } return previewImage;
        }
    },

    updateImagePreview : function (rootDomNode, divReference, jsonObj){
        if(sv.isNullOrEmpty(jsonObj.imagenes.plano)) {
            if(sv.isNotNullOrEmpty(jsonObj.imagenes.sello)) {
                switch (jsonObj.imagenes.sello) {
                    case L.IMAGES.SELLO_LAT_CUAD:
                        rootDomNode.render( <SelloCuadroLateral jsonObj={jsonObj} /> );
                        break;
                    case L.IMAGES.SELLO_LAT_HOR:
                        rootDomNode.render( <SelloHorizontalLateral jsonObj={jsonObj} /> );
                        break;
                    case L.IMAGES.SELLO_LAT_VER:
                        rootDomNode.render( <SelloVerticalLateral jsonObj={jsonObj} /> );
                        break;
                    case L.IMAGES.SELLO_FONDO_CUAD:
                        rootDomNode.render( <IsoCuadrado jsonObj={jsonObj} /> );
                        break;
                    case L.IMAGES.SELLO_FONDO_HOR:
                        rootDomNode.render( <IsoHorizontal jsonObj={jsonObj} /> );
                        break;
                    case L.IMAGES.SELLO_FONDO_VER:
                        rootDomNode.render( <IsoVertical jsonObj={jsonObj} /> );
                        break;
                    default:
                        return;
                }

            } else {
                switch (jsonObj.imagenes.fuelle) {
                    case L.IMAGES.FUE_CUADRO:
                        rootDomNode.render( <IsoCuadrado jsonObj={jsonObj} /> );
                        break;
                    case L.IMAGES.FUE_RECH:
                        rootDomNode.render( <IsoHorizontal jsonObj={jsonObj} /> );
                        break;
                    case L.IMAGES.FUE_RECV:
                        rootDomNode.render( <IsoVertical jsonObj={jsonObj} /> );
                        break;
                    default:
                        return;
                }
            }

        } else {
            switch (jsonObj.imagenes.plano) {
                case L.IMAGES.PL_CUADRO :
                    rootDomNode.render( <PlanoCuadro jsonObj={jsonObj} /> );
                    break;
                case L.IMAGES.PL_RECH :
                    rootDomNode.render( <PlanoHorizontal jsonObj={jsonObj} /> );
                    break;
                case L.IMAGES.PL_RECV :
                    rootDomNode.render( <PlanoVertical jsonObj={jsonObj} /> );
                    break;
                default :
                    return;
            }
        }
    }
}

export default imageValidator;