import React, { Component } from "react";

import devicesIcon from "../../assets/icon/dispositivos-electronicos.png";
import frozenFoodIcon from "../../assets/icon/alimentos-congelados.png";
import medicineIcon from "../../assets/icon/medicamento.png";
import L from "../i18n/label.json";

class About extends Component {
    render() {
        return(
            <div className="container-fluid wow fadeIn ptb-5" id="aboutSectionId" data-wow-duration="1.5s">
                <div className="row p-20">
                    <div className="col-lg-6 has-img-about"></div>
                    <div className="col-lg-6">
                        <div className="row title-right marl-5">
                            <div className="col-sm-8">
                                <hr className="hr-line-left"/>
                            </div>
                            <div className="col-sm-4">
                                <h2>{L.TITULO.NOSOTROS}</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="justify pl-30 pr-30">
                                    En Grupo Ades de México somos la empresa número uno a nivel nacional en desarrollo y
                                    producción de empaques plásticos y bolsas.<br/><br/>Con 25 años de experiencia nos hemos ganado la
                                    confianza para garantizar la seguridad de nuestros clientes y consumidores finales gracias al
                                    apoyo de nuestro equipo de trabajo.<br/><br/>Nos hemos posicionado en diferentes industrias como:<br/><br/>
                                </p>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <p>{L.TITULO.FARMACEUT}</p>
                                        <img src={medicineIcon} className="food" alt="Comida Congelada"/>
                                    </div>
                                    <div className="col-sm-4">
                                        <p>{L.TITULO.ALIMENT}</p>
                                        <img src={frozenFoodIcon} className="food" alt="Comida Congelada"/>
                                    </div>
                                    <div className="col-sm-4">
                                        <p>{L.TITULO.ELECTRON}</p>
                                        <img src={devicesIcon} className="food" alt="Comida Congelada"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;